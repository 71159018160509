exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-dankeschoen-js": () => import("./../../../src/pages/dankeschoen.js" /* webpackChunkName: "component---src-pages-dankeschoen-js" */),
  "component---src-pages-datenschutzerklaerung-js": () => import("./../../../src/pages/datenschutzerklaerung.js" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-js" */),
  "component---src-pages-dosen-js": () => import("./../../../src/pages/dosen.js" /* webpackChunkName: "component---src-pages-dosen-js" */),
  "component---src-pages-groessentabelle-js": () => import("./../../../src/pages/groessentabelle.js" /* webpackChunkName: "component---src-pages-groessentabelle-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klassik-js": () => import("./../../../src/pages/klassik.js" /* webpackChunkName: "component---src-pages-klassik-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-modern-js": () => import("./../../../src/pages/modern.js" /* webpackChunkName: "component---src-pages-modern-js" */),
  "component---src-pages-pflegeanleitung-js": () => import("./../../../src/pages/pflegeanleitung.js" /* webpackChunkName: "component---src-pages-pflegeanleitung-js" */),
  "component---src-pages-produkte-js": () => import("./../../../src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-retoure-js": () => import("./../../../src/pages/retoure.js" /* webpackChunkName: "component---src-pages-retoure-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-transparenz-js": () => import("./../../../src/pages/transparenz.js" /* webpackChunkName: "component---src-pages-transparenz-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-versand-js": () => import("./../../../src/pages/versand.js" /* webpackChunkName: "component---src-pages-versand-js" */),
  "component---src-pages-widerrufsbelehrung-js": () => import("./../../../src/pages/widerrufsbelehrung.js" /* webpackChunkName: "component---src-pages-widerrufsbelehrung-js" */),
  "component---src-templates-comming-soon-js": () => import("./../../../src/templates/comming-soon.js" /* webpackChunkName: "component---src-templates-comming-soon-js" */),
  "component---src-templates-products-basic-js": () => import("./../../../src/templates/Products/basic.js" /* webpackChunkName: "component---src-templates-products-basic-js" */),
  "component---src-templates-products-franka-js": () => import("./../../../src/templates/Products/franka.js" /* webpackChunkName: "component---src-templates-products-franka-js" */),
  "component---src-templates-products-heinrich-js": () => import("./../../../src/templates/Products/heinrich.js" /* webpackChunkName: "component---src-templates-products-heinrich-js" */),
  "component---src-templates-products-herzschlag-js": () => import("./../../../src/templates/Products/herzschlag.js" /* webpackChunkName: "component---src-templates-products-herzschlag-js" */),
  "component---src-templates-products-horizon-js": () => import("./../../../src/templates/Products/horizon.js" /* webpackChunkName: "component---src-templates-products-horizon-js" */),
  "component---src-templates-products-icon-js": () => import("./../../../src/templates/Products/icon.js" /* webpackChunkName: "component---src-templates-products-icon-js" */),
  "component---src-templates-products-karl-js": () => import("./../../../src/templates/Products/karl.js" /* webpackChunkName: "component---src-templates-products-karl-js" */),
  "component---src-templates-products-karla-kohler-js": () => import("./../../../src/templates/Products/karla-kohler.js" /* webpackChunkName: "component---src-templates-products-karla-kohler-js" */),
  "component---src-templates-products-max-js": () => import("./../../../src/templates/Products/max.js" /* webpackChunkName: "component---src-templates-products-max-js" */),
  "component---src-templates-products-nureinherz-js": () => import("./../../../src/templates/Products/nureinherz.js" /* webpackChunkName: "component---src-templates-products-nureinherz-js" */)
}

